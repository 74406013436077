.sku-creation-page {
  position: absolute;
  left: 14%;
  top: 14%;
  width: 70%;
  font-family: Inter;
}
.adminskuheader {
  font-size: 2.3vw;
  margin-left: 37%;
}
.sku-form {
  border-radius: 7px;
  border: 0.7px solid #c9c9c9;
  padding: 4%;
  padding-bottom: 2.5%;
}
.flex {
  display: flex;
  gap: 5%;
  padding-bottom: 3%;
}
#adminproduct,
#adminbrand {
  width: 30vw;
}
.btn-control {
  display: flex;
  align-items: center;
  gap: 3%;
}
#productbutton,
#brandbutton {
  width: 3.2vw;
  height: 3.2vw;
  border-radius: 50%;
  cursor: pointer;
  background: #0081df;
  color: #ffffff;
  border: none;
  margin-top: 4%;
}
#productbutton > span,
#brandbutton > span {
  display: flex;
  justify-content: center;
  align-items: center;
}
#productbutton > span > svg,
#brandbutton > span > svg {
  height: 1.7vw;
  width: 1.7vw;
}
.adminaddproducttext,
.adminaddbrandtext {
  color: #0081df;
  font-family: Inter;
  font-size: 1.4vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 4%;
}
#adminnextbutton {
  width: 10vw;
  height: 40px;
  border-radius: 7px;
  background-color: #26a69a;
  border: none;
  margin-top: 2%;
  margin-left: 83%;
  cursor: pointer;
}
.adminnextbuttontext {
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}
.adminnoskufound {
  /* position: fixed;
  top: 40%;
  left: 38%; */
  margin-left: 20%;
  margin-top: 3%;
  width: 40vw;
  height: 15vw;
  border-radius: 10px;
  border: 0.7px;
  border: 0.7px solid #b3b3b3;
  box-shadow: 0px 4px 4px 0px #00000040;
  z-index: 100;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4%;
}
.adminnoskufound > svg {
  height: 3.5vw;
  width: 3.5vw;
}
.adminaddnewsku {
  font-family: Manrope;
  font-size: 26px;
  font-weight: 600;
  line-height: 41px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 1%;
}
.cancel-addsku {
  display: flex;
  gap: 3%;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2.3%;
  margin-bottom: 1%;
}
.adminsubmitbuttontext {
  width: 86px;
  height: 24px;
  top: 669px;
  left: 964px;
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}
.adminskuclosebutton {
  /* position: fixed;
  top: 55%;
  left: 47%; */
  width: 126px;
  height: 40px;
  border-radius: 7px;
  background: #898989;
  cursor: pointer;
  border: none;
  /* z-index: 200; */
}
#adminsubmitbutton {
  /* position: fixed;
  top: 55%;
  left: 59%; */
  width: 140px;
  height: 40px;
  border-radius: 7px;
  background: #489a34;
  cursor: pointer;
  border: none;
  /* z-index: 200; */
}
.adminskuclosebuttontext {
  width: 67px;
  height: 24px;
  /* top: 669px;
  left: 834px; */
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.adminset {
  display: flex;
  justify-content: flex-end;
  padding-right: 50px;
  padding-top: 18px;
  gap: 20px;
}
.adminshowadditionalfield {
  width: 701px;
  height: fit-content;
  border-radius: 7px;
  border: 0.7px solid #c9c9c9;
  margin-top: 3%;
  margin-left: 15%;
  padding-bottom: 20px;
}
/* .adminset{
     justify-content: end; 
     position: absolute;
  } */

.sizeinput {
  width: 474px;
  height: 35px;
  /* top: 602px;
      left: 876px; */
  border-radius: 7px;
  border: 0.7px solid #c9c9c9;
  /* border: 0.7px; */
  margin-left: 47px;
  margin-top: 20px;
  padding-left: 2%;
  font-size: 15px;
}

.sizelabel {
  width: 140px;
  height: 24px;
  /* top: 618px;
      left: 825px; */
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  margin-left: 20px;
  text-align: left;
}

.gst {
  display: flex;
  gap: 5.5%;
  padding-top: 30px;
  align-items: center;
}

/* .installation {
  display: flex;
  gap: 10%;
  padding-top: 30px;
  align-items: center;

}

.install-label {
  width: 53px;
  height: 24px;
  top: 690px;
  left: 712px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 60px;

} */

.install-input {
  width: 489px;
  height: 39px;
  border-radius: 5px;
  border: 0.7px solid #c9c9c9;
}

.installation {
  display: flex;
  gap: 10%;
  padding-top: 30px;
  align-items: center;
  padding-bottom: 20px;
}

.install-label {
  width: 53px;
  height: 24px;
  top: 690px;
  left: 712px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 60px;
}
.gstlabel {
  width: 53px;
  height: 24px;
  top: 690px;
  left: 712px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 90px;
}

.install-input {
  width: 489px;
  height: 39px;
  border-radius: 5px;
  border: 0.7px solid #c9c9c9;
}
.GSTinput {
  width: 489px;
  height: 39px;
  border-radius: 5px;
  border: 0.7px solid #c9c9c9;
}
.adminskucreated {
  width: 701px;
  height: 251px;
  /* top: 386px;
  left: 591px; */
  border-radius: 7px;
  border: 0.7px solid #c9c9c9;
  box-shadow: 0px 4px 4px 0px #00000040;
  background-color: white;
  position: absolute;
  top: 60%;
  left: 10%;
  text-align: center;
}
.adminskucreated svg {
  padding-top: 25px;
}
.adminskutext {
  padding-top: 50px;
  color: #000;
  text-align: center;
  font-family: Manrope;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.adminskucreatedtext {
  color: #000;
  text-align: center;
  font-family: Manrope;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.adminbackbutton {
  width: 140px;
  height: 40px;
  margin-top: 3%;
  border-radius: 7px;
  margin-bottom: 25px;
  background: #489a34;
  cursor: pointer;
  color: #fff;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
}
.admincancelbutton {
  width: 126px;
  height: 40px;
  /* top: 737px;
  left: 978px; */
  border-radius: 7px;
  background-color: #d2d2d2;
  cursor: pointer;
  color: #fff;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
}
.admingenerate {
  width: 140px;
  height: 40px;
  top: 737px;
  left: 1114px;
  border-radius: 7px;
  background-color: #489a34;
  cursor: pointer;
  border: none;
}
.admingeneratebuttontext {
  width: 126px;
  height: 40px;
  /* top: 737px;
      left: 978px; */
  border-radius: 7px;
  cursor: pointer;
  color: #fff;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
}
.adminskulistable {
  /* border-radius: 7px; */
  border: 0.7px solid #c9c9c9;
  background: #fff;
  margin-top: 4%;
  width: 100%;
  /* border-collapse: collapse; */
}
.adminskulistable > thead {
  background-color: #5b9995;
  color: white;
  border-radius: 7px 7px 0px 0px;
  padding: 2%;
}
.adminskutablerow > th {
  font-weight: 500;
  font-size: 1.5vw;
  border-width: 0px;
  padding: 1.5%;
}
.adminskutd {
  text-align: center;
  padding: 1.3%;
  width: 25%;
  /* width: 5vw; */
}
.adminskutd:first-child {
  width: 18%;
}
.adminskutd:last-child {
  text-align: left;
  /* width: 10vw; */
  width: 30%;
}

.install_required {
  padding: 30px 0px 10px 30px;
}
.install_required > label {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  margin-left: 20px;
  text-align: left;
}

.goods_service {
  display: flex;
  align-items: center;
  margin-left: 43px;
  width: fit-content;
}

.goods_service > p {
  margin-right: 20px;
}
.goods_service > label,
.goods_service > p {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  margin-left: 10px;
  text-align: left;
  margin-right: 20px;
}
